import React, { useRef } from "react";

import Slider from "react-slick";
import Img from "gatsby-image";
import { navigate } from "gatsby";

import { BannerContainer, Slide } from "./Banner.styles";
import Button from "components/button/Button";
import { white } from "theme/colors";

const Banner = (props) => {
  //PROPS
  const { data, dimensions } = props;
  //PROPS

  //REF
  const slider = useRef(null);
  //REF

  //DEFS
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    draggable: true,
    adaptiveHeight: false,
    slidesToShow: 1,
    swipe: true,
    fade: false,
    slidesToScroll: 1,
    swipeToSlide: false,
    autoplay: true,
    autoplaySpeed: 4000,
    afterChange: (e) => {
      //setDisabled(false);
    },
    beforeChange: (e) => {
      //setDisabled(true);
    }
  };
  //DEFS

  return (
    <BannerContainer>
      <Slider {...settings} ref={slider}>
        {data.map((slide, index) => {
          const image = slide.image?.localFile?.childImageSharp?.fluid;
          const imageM = slide.imageMobile?.localFile?.childImageSharp?.fluid;

          return (
            <Slide key={index}>
              {dimensions.width > dimensions.height || !imageM ? (
                <Img fluid={image} alt={slide.image?.altText} />
              ) : (
                <Img fluid={imageM} alt={slide.imageMobile?.altText} />
              )}
              <div className="info">
                {slide.title && <h2>{slide.title}</h2>}
                {slide.subtitle && <h3>{slide.subtitle}</h3>}
              </div>
              <div className="button_area">
                {slide.buttons.map((button, indexBtn) => {
                  return (
                    <div key={indexBtn}>
                      <Button
                        text={
                          button.buttonAction === "Go to form"
                            ? button.label
                            : button.link.title
                        }
                        color={white.default}
                        backgroundColor={
                          button.buttonStyle === "Color"
                            ? button.buttonColor
                            : null
                        }
                        isLink={button.buttonAction !== "Go to form"}
                        onclick={
                          button.buttonAction === "Go to form"
                            ? () =>
                                navigate("/#cform", {
                                  state: { title: slide.title }
                                })
                            : button.link.url
                        }
                        isBordered={
                          button.buttonStyle === "Border" ? true : false
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </Slide>
          );
        })}
      </Slider>
    </BannerContainer>
  );
};

export default Banner;
